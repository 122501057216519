import React from "react";

import { navigate } from "gatsby";

import { SEO } from "components/common/SEO";

import { Layout } from "components/layout";

import { useAuth } from "auth";
import { ViewHeader } from "components/view/ViewHeader";

export default function LoginPage() {
  const { login, token } = useAuth();

  React.useEffect(() => {
    if (token) {
      login?.clearData();
      localStorage.removeItem('token');
    }
    return () => {
      navigate("/");
    };
  }, [token]);

  return (
    <Layout fullscreen dark>
      <SEO title="Logout" />
      <ViewHeader dark />
    </Layout>
  );
}
